import { gsap } from 'gsap';
import { DrawSVGPlugin } from 'gsap/DrawSVGPlugin';
import { CustomEase } from 'gsap/CustomEase';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollSmoother } from 'gsap/ScrollSmoother';
import { MotionPathPlugin } from 'gsap/MotionPathPlugin';
import { initLatestSingleAnimation } from './animations/single';
import { initMusicRingsAnimation } from './animations/music-rings';
import { initAboutAnimation } from './animations/about-elbows';
import { initNewsAnimation } from './animations/news-triangles';
import { initShopAnimation } from './animations/shop-diamonds';
import { initFooterAnimation } from './animations/footer-circles';

gsap.registerPlugin(
  DrawSVGPlugin,
  CustomEase,
  ScrollTrigger,
  ScrollSmoother,
  MotionPathPlugin,
);

// let smooth = ScrollSmoother.create({
//   smooth: 2,
//   effects: true,
// });

gsap.registerEffect({
  name: 'svgslide',
  effect: (targets, config) => {
    return gsap.fromTo(
      targets,
      {
        yPercent: config.startY || 0,
      },
      {
        duration: config.duration,
        yPercent: -100,
        ease: 'power2.in',
      },
    );
  },
  defaults: { duration: 2 },
  extendTimeline: true,
});

const navLinks = document.querySelectorAll('.brand a, .nav-link');
navLinks.forEach((link) => {
  const id = link.getAttribute('href');
  const el = document.querySelector(id);
  const offset = window.innerHeight * (el.getAttribute('data-offset') || 0);

  link.addEventListener('click', (e) => {
    e.preventDefault();

    const scrollPos = el.offsetTop + offset;
    // smooth.scrollTo(link.getAttribute('href'), true, 'top 64px'),
    window.scrollTo({
      behavior: 'smooth',
      top: scrollPos,
    });
  });
});

// const mainTimeline = gsap.timeline({});
initLatestSingleAnimation();
initMusicRingsAnimation();
initShopAnimation();
initAboutAnimation();
initNewsAnimation();
initFooterAnimation();
