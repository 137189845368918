import { gsap } from 'gsap';
import { setSVGSlideTrigger } from './helpers';

export const initFooterAnimation = () => {
  const footerPaths = gsap.utils.toArray('.footer-circles path');
  const footerDuration = 2;
  const footer = gsap.timeline({
    scrollTrigger: '#footer',
    stagger: 0.1,
    paused: true,
  });
  footerPaths.forEach((path, index) => {
    footer.add(
      gsap.to(path, {
        startAt: {
          x: -25,
        },
        keyframes: {
          x: [-25 - index, 25 + index],
          ease: 'none',
        },
        repeat: -1,
        yoyo: true,
        duration: index * 0.01 + footerDuration,
        transformOrigin: 'center',
      }),
      '<' + index * 0.01,
    );
  });
};
