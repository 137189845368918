import { gsap } from 'gsap';

export const initLatestSingleAnimation = () => {
  const tlProgress = { progress: 0 };

  const overlappingLinesTL = gsap
    .timeline({ paused: true })
    .fromTo(
      '#bottom path',
      {
        drawSVG: '0 100%',
      },
      {
        duration: 1,
        stagger: () => gsap.utils.random(-0.03, 0.03, 0.001),
        drawSVG: '100% 100%',
        ease: 'none',
      },
    )
    .fromTo(
      '#bottom line',
      {
        drawSVG: '0 100%',
      },
      {
        drawSVG: '100% 100%',
        duration: 0.5,
        stagger: () => gsap.utils.random(-0.03, 0.03, 0.001),
        // drawSVG: '0 100%',
        ease: 'none',
      },
    );
  const initSquiggleTrigger = () => {
    const squigTL = gsap.timeline({
      scrollTrigger: {
        trigger: '#single',
        start: 'top top',
        end: 'center top',
        scrub: 1,
      },
    });

    squigTL
      .to('.squiggles svg', {
        scale: 1.5,
        yPercent: 10,
        xPercent: -10,
        duration: 3.5,
        ease: 'smooth.out',
      })
      .fromTo(
        '.squiggles #top path',
        {
          drawSVG: '0 100%',
          // immediateRender: false,
        },
        {
          drawSVG: '100% 100%',
          stagger: () => gsap.utils.random(-0.04, 0.04, 0.001),
          delay: 0.5,
          duration: 3.5,
        },
        '<',
      )
      .to(
        tlProgress,
        {
          progress: 1,
          ease: 'smooth.inOut',
          delay: 0.5,
          duration: 3.5,
          onUpdate: () => {
            overlappingLinesTL.progress(tlProgress.progress);
          },
        },
        '<',
      );
  };

  const drawSquiggles = () => {
    const squiggles = gsap.timeline();
    function startSquigTrig() {
      if (this.progress() > 0.5) initSquiggleTrigger();
    }
    squiggles
      .fromTo(
        '#top path',
        { drawSVG: '0 0' },
        { duration: 2, stagger: 0.1, drawSVG: '0 100%' },
      )
      .to(
        overlappingLinesTL,
        {
          time: overlappingLinesTL.duration(),
          duration: overlappingLinesTL.duration(),
          ease: 'smooth.inOut',
          onUpdate: startSquigTrig,
        },
        '<0.1',
      );
  };

  // drawSquiggles();
  initSquiggleTrigger();
};
