import { gsap } from 'gsap';

export const setSVGSlideTrigger = (tlConfig, svgWrapClass, config) => {
  try {
    if (!tlConfig.trigger) throw 'No Trigger';
    const tl = gsap
      .timeline({
        scrollTrigger: {
          start: 'center bottom',
          end: 'bottom top',
          scrub: true,
          ...tlConfig,
        },
      })
      .svgslide(`.svg-wrap${svgWrapClass}`, { duration: 1, ...config }, 0);
  } catch (e) {
    console.log('trigger required in config');
  }
};
