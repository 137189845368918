import { gsap } from 'gsap';
import { setSVGSlideTrigger } from './helpers';

export const initMusicRingsAnimation = () => {
  const ringset = document.querySelectorAll('.rings [class^=ringset]');

  const ringProps = {
    scale: 1.45,
    strokeWidth: 2,
    transformOrigin: 'center',
    duration: 4,
  };
  const rings = gsap.timeline({
    scrollTrigger: {
      trigger: '#music',
      start: 'top bottom',
      end: 'bottom top',
      scrub: true,
      ease: 'smooth.inOut',
    },
  });
  const wrapTL = setSVGSlideTrigger({ trigger: '#music' }, '.--music');
  rings
    .from('.rings circle', {
      stagger: 0.01,
      xPercent: 'random(-200%,200%, 10)',
      yPercent: 'random(-100%,200%, 10)',
      rotate: 'random(-360, 360, 10)',
      drawSVG: '0 0',
      opacity: 0,
      // strokeWidth: 0.5,
      duration: 1.5,
      ease: 'smooth.inOut',
      onComplete: () => {
        ringset.forEach((set) => (set.style.opacity = 1));
      },
    })
    .to('.rings .ringset', {
      ...ringProps,
    })
    .to(
      '.rings .ringset2',
      {
        x: '200%',
        ...ringProps,
      },
      '<',
    )
    .to(
      '.rings .ringset3',
      {
        x: '-200%',
        ...ringProps,
      },
      '<',
    );
};
