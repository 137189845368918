import { gsap } from 'gsap';
import { MotionPathPlugin } from 'gsap/MotionPathPlugin';
import { setSVGSlideTrigger } from './helpers';

export const initShopAnimation = () => {
  const wrapTL = setSVGSlideTrigger(
    {
      trigger: '#shop',
      start: 'top bottom',
      end: 'bottom top+=5%',
    },
    '.--shop',
    {
      startY: 50,
    },
  );
  const diamonds = gsap.timeline({
    scrollTrigger: {
      trigger: '#shop',
      scrub: 1,
      start: 'top center',
      end: 'bottom top',
    },
  });
  const diamondPanel = document.querySelector('.svg-wrap.--shop'),
    diamondLeft = document.querySelector('.diamonds.-left'),
    diamondRight = document.querySelector('.diamonds.-right');
  const deltaLeft = MotionPathPlugin.getRelativePosition(
    diamondLeft,
    diamondPanel,
    [0.5, 0.5],
    [-0.05, -0.05],
  );
  const deltaRight = MotionPathPlugin.getRelativePosition(
    diamondRight,
    diamondPanel,
    [0.5, 0.5],
    [1.1, 1.1],
  );

  const diamondEnterProps = {
    opacity: 0,
    scale: 0.9,
    transformOrigin: 'center',
    stagger: 0.075,
    ease: 'bounce.out',
    duration: 1,
  };
  diamonds
    .from('.diamonds.-left path', {
      ...diamondEnterProps,
    })
    .from(
      '.diamonds.-right path',
      {
        ...diamondEnterProps,
      },
      '<0.2',
    )
    .to('.diamonds.-left', {
      x: '+=' + deltaLeft.x,
      y: '+=' + deltaLeft.y,
      scale: 2,
      duration: 4,
      ease: 'power1.out',
    })
    .to(
      '.diamonds.-right',
      {
        x: `+=${deltaRight.x}`,
        y: `+=${deltaRight.y}`,
        scale: 2,
        // strokeWidth: 1,
        duration: 4,
        ease: 'power1.out',
      },
      '<',
    )
    .from(
      '.shop-items > *',
      {
        opacity: 0,
        scale: 1.05,
        ease: 'elastic.out',
        stagger: 0.1,
        duration: 1,
      },
      '<40%',
    )
    .to(
      '.diamonds path',
      {
        strokeWidth: 0.75,
      },
      '<',
    )
    .to('.diamonds.-left', {
      // 684 x 684
      // xPercent: -50,
      // yPercent: -50,
      x: '+=' + deltaLeft.x,
      y: '+=' + deltaLeft.y,
      opacity: 0,
      scale: 2,
      duration: 4,
      ease: 'power1.in',
    })
    .to(
      '.diamonds.-right',
      {
        x: `+=${deltaRight.x}`,
        y: `+=${deltaRight.y}`,
        scale: 2,
        opacity: 0,
        duration: 4,
        ease: 'power1.in',
      },
      '<',
    );
};
