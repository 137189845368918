import { gsap } from 'gsap';
import { setSVGSlideTrigger } from './helpers';

export const initNewsAnimation = () => {
  const tl = setSVGSlideTrigger({ trigger: '#news' }, '.--news');
  const triangles = gsap
    .timeline({
      scrollTrigger: {
        trigger: '#news',
        scrub: 1,
        start: 'top center+=' + window.innerHeight / 2,
        end: 'bottom center',
        ease: 'smooth.inOut',
      },
    })
    .to('.triangles .triangle-group', {
      opacity: 1,
      yPercent: -50,
      duration: 3,
    })
    .to(
      '.triangles path',
      {
        startAt: {
          yPercent: 100,
        },
        keyframes: [
          {
            yPercent: 0,
          },
          {
            yPercent: -20,
          },
        ],
        duration: 3,
      },
      0,
    )
    // .from(
    //   '.triangles path',
    //   {
    //     y: '+=200%',
    //     strokeWidth: 0.25,
    //     opacity: 0.25,
    //     // stagger: 0.1,
    //     // ease: 'smooth.inOut',
    //     // duration: 1,
    //   },
    //   {
    //     // y: '-=50vh',
    //     y: '-=400%',
    //     xPercent: 50,
    //     // yPercent: 0,
    //     strokeWidth: 1,
    //     opacity: 1,
    //     stagger: 0.01,
    //     ease: 'smooth.inOut',
    //     duration: 2,
    //   },
    //   0,
    // )
    .from(
      '.news-item',
      {
        opacity: 0,
        y: '-=20',
        stagger: 0.1,
        duration: 0.5,
      },
      '<1`',
    );
};
