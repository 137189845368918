import { gsap } from 'gsap';

export const initAboutAnimation = () => {
  const elbows = gsap.timeline({
    scrollTrigger: {
      trigger: '#about',
      scrub: 1,
      ease: 'smooth.inOut',
      start: 'top top+=15%',
      end: 'bottom center',
    },
  });
  elbows
    .from('.elbows #mask, .elbows #shadows', { opacity: 0, duration: 0.001 }, 0)
    .fromTo(
      '.elbows #bottomLines path',
      {
        drawSVG: '100% 100%',
      },
      {
        drawSVG: '0 100%',
        stagger: () => gsap.utils.random(-0.1, 0.1, 0.001),
        duration: 3.5,
      },
    )
    .from(
      '.elbows #topLines path',
      {
        drawSVG: '0 0',
        stagger: () => gsap.utils.random(-0.1, 0.1, 0.001),
        duration: 3.5,
      },
      '<',
    )

    .from(
      '#about .panel-content',
      {
        yPercent: 5,
        opacity: 0,
        duration: 0.5,
      },
      '<-=.5',
    )
    .to('.elbows #bottomLines path', {
      drawSVG: '0 0',
      stagger: () => gsap.utils.random(-0.1, 0.1, 0.001),
      delay: 1,
      duration: 3,
    })
    .to(
      '.elbows #topLines path',
      {
        drawSVG: '100% 100%',
        stagger: () => gsap.utils.random(-0.1, 0.1, 0.001),
        duration: 3,
      },
      '<',
    )
    .to(
      '#about .panel-content',
      {
        opacity: 0,
        duration: 0.5,
      },
      '<0.5',
    )
    .to(
      '#elbows',
      {
        scale: 1.5,
        transformOrigin: 'center',
        xPercent: 15,
        duration: 5.5,
      },
      0,
    );
};
